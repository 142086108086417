import React, { memo, useMemo, useRef, useSyncExternalStore } from 'react';
import SessionTitlePopup from '../SessionDetail/SessionTitlePopup';
import EditRecordingHeader from './EditRecordingHeader';
import EditRecordingPlayerControls from './EditRecordingPlayerControls';
import useTranscriptPage from '../TranscriptPage/useTranscriptPage';
import FullTranscript from '@/components/molecules/Transcript/FullTranscript';
import { core } from '@/stores/core';
import { classnames } from '@/libs/utils';
import FreeTrialUpgradeNag from '@/components/molecules/FreeTrial/FreeTrialUpgradeNag';
import { resetFullClipChanges } from '@/libs/fullRecordingEdit';
import { ClipsContextProvider } from '@/context/ClipsContext/ClipsContext';
import { SpeakerSegmentContextProvider } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import ClipPlayer from '@/Pages/Clip/ClipPlayer/ClipPlayer';
import { TIMELINE_DISPLAY_TYPE } from '@/Pages/Clip/ClipPlayer/ClipTimelineV2/ClipTimelineTypes';

function EditRecordingPage() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const containerRef = useRef<HTMLDivElement>(null);

  const videoAssetId = useMemo(() => {
    // TODO @dusangc: Update with actual value
    return undefined;
  }, []);

  const { createClip, isAudio } = useTranscriptPage();

  function resetFullRecordingChanges() {
    if (!videoAssetId || !coreStore.content || isNaN(Number(coreStore.content!.duration))) return;
    resetFullClipChanges(videoAssetId!, Number(coreStore.content!.duration));
  }

  return (
    <ClipsContextProvider clipId={videoAssetId} shared={false} showLoaderOnPage={false}>
      <SpeakerSegmentContextProvider>
        <div className="grid h-full w-full grid-cols-[1fr] grid-rows-[auto,max-content] pb-4 pr-4">
          <div className="relative grid w-full grid-cols-[max-content,1fr] grid-rows-[1fr] gap-4" ref={containerRef}>
            <FullTranscript onCreateClip={createClip} isEdit={true} videoAssetId={videoAssetId} />
            <div
              className={classnames('flex grow flex-col transition-all duration-[400ms] ease-in-out', {
                hidden: isAudio
              })}
            >
              <div className="flex w-full items-center justify-between py-4">
                <SessionTitlePopup />
                <FreeTrialUpgradeNag />
                <EditRecordingHeader />
              </div>
              <div className="inline-grid h-full">
                <ClipPlayer timelineDisplayType={TIMELINE_DISPLAY_TYPE.NONE} playerClasses="lg:p-16" />
              </div>
            </div>
          </div>
          <EditRecordingPlayerControls onResetChanges={resetFullRecordingChanges} />
        </div>
      </SpeakerSegmentContextProvider>
    </ClipsContextProvider>
  );
}

export default memo(EditRecordingPage);
