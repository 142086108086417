import React, { memo } from 'react';
import PlaybackControls from '../TranscriptPage/PlayerControls/PlaybackControls';
import PlayerToggle from '../TranscriptPage/PlayerControls/PlayerToggle';
import usePlayerControls from '../TranscriptPage/PlayerControls/usePlayerControls';
import Timeline from '@/components/molecules/Timeline';
import Button from '@/components/atoms/Button/Button';

function EditRecordingPlayerControls({ onResetChanges }: { onResetChanges: () => void }) {
  const { isTimelineCollapsed } = usePlayerControls();

  return (
    <div className="w-full rounded-2xl bg-white p-3 shadow-lg">
      <div className="flex justify-between px-1">
        <div className="flex h-9 w-32 cursor-pointer items-center justify-start rounded-sm">
          <Button variation="outline" trackingId="reset-full-edit-changes" onClick={onResetChanges}>
            Reset changes
          </Button>
        </div>
        <PlaybackControls />
        <PlayerToggle />
      </div>
      <Timeline clips={[]} visible={!isTimelineCollapsed} />
    </div>
  );
}

export default memo(EditRecordingPlayerControls);
