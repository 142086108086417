import { RadioGroup } from '@headlessui/react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { IconColorPicker } from '@tabler/icons-react';
import ImagePicker from '../ImagePicker/ImagePicker';
import BrandKitInlineEmptyState from '../EmptyStates/BrandKitInlineEmptyState';
import { ColorPicker } from '@/components/atoms/ColorPicker/ColorPicker';
import { classnames } from '@/libs/utils';
import { updateKeyInMagicLayout } from '@/stores/clip';
import useBrandKitComponent from '@/Pages/BrandKit/components/useBrandKitComponent';
import { getBrandKitUrl } from '@/Pages/BrandKit/components/utils';
import { showErrorToast } from '@/libs/toast/toast';
import { BRAND_KIT_MAX_FILE_SIZE } from '@/Pages/BrandKit/constants';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function ClipBackgroundSelector({ hideTitle = false }: { hideTitle?: boolean }) {
  const { clipId, clipData } = useClipsContext();

  const { brandKitStore, clipBrandKitStore, onFilesUpload, deleteItem } = useBrandKitComponent(
    'background',
    'Background',
    clipData.id
  );
  const [selectedColor, setSelectedColor] = useState(clipData.asset_metadata.magicLayout?.backgroundColor);
  const [selectedImage, setSelectedImage] = useState(clipData.asset_metadata.magicLayout?.backgroundImage);

  function onBgColorChange(hex: string) {
    setSelectedColor(hex);
    updateKeyInMagicLayout(clipId, 'backgroundColor', hex);
  }

  useEffect(() => {
    setSelectedColor(clipData.asset_metadata.magicLayout?.backgroundColor);
  }, [clipData.asset_metadata.magicLayout?.backgroundColor]);

  useEffect(() => {
    if (clipData.asset_metadata.magicLayout?.backgroundImage)
      setSelectedImage(clipData.asset_metadata.magicLayout?.backgroundImage);
    setSelectedBackground(getSelectedBackgroundValue());
  }, [clipData.asset_metadata.magicLayout?.backgroundImage]);

  function onBgImageChange(url: string) {
    setSelectedImage(url);
    updateKeyInMagicLayout(clipId, 'backgroundImage', url);
  }

  function uploadFiles(files: File[]) {
    onFilesUpload(files)
      .then(uploadedFiles => {
        if (uploadedFiles && uploadedFiles.length) {
          updateKeyInMagicLayout(clipId, 'backgroundImage', getBrandKitUrl(uploadedFiles[0].name, clipData.id));
        }
      })
      .catch(() => {
        showErrorToast(
          `Your background couldn't be uploaded. Please make sure your file is in JPEG or PNG format and doesn't exceed ${BRAND_KIT_MAX_FILE_SIZE}MB, then give it another go.`
        );
      });
  }

  const backgroundProperties = ['backgroundColor', 'backgroundImage'];

  const brandImagesUrls = useMemo(() => {
    return brandKitStore.background.map(image => getBrandKitUrl(image));
  }, [brandKitStore.background]);

  const [selectedBackground, setSelectedBackground] = useState(getSelectedBackgroundValue());

  function getSelectedBackgroundValue() {
    return clipData.asset_metadata.magicLayout?.backgroundImage ? 'backgroundImage' : 'backgroundColor';
  }

  const setBackgroundType = (selectedBackgroundType: string) => {
    setSelectedBackground(selectedBackgroundType);

    if (!selectedImage) return; // only radio button changed, not actual clip value
    if (selectedBackgroundType === 'backgroundColor') {
      updateKeyInMagicLayout(clipId, 'backgroundImage', null);
      updateKeyInMagicLayout(clipId, 'backgroundColor', selectedColor);
    } else if (selectedBackgroundType === 'backgroundImage') {
      updateKeyInMagicLayout(clipId, 'backgroundImage', selectedImage);
    }
  };

  const uploadedImagesMap = useMemo(() => {
    return clipBrandKitStore.background.reduce(
      (acc, imageName) => ({
        ...acc,
        [getBrandKitUrl(imageName, clipData.id)]: imageName
      }),
      {}
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipBrandKitStore]);

  function onDelete(imageUrl: string) {
    deleteItem(uploadedImagesMap[imageUrl]);
  }

  return (
    <>
      {!hideTitle && <div className="mt-5 font-semibold">Background</div>}
      <RadioGroup
        value={selectedBackground}
        onChange={setBackgroundType}
        className="mt-1.5 divide-y rounded-md border border-slate-200"
      >
        {backgroundProperties.map(backgroundProperty => (
          <RadioGroup.Option key={backgroundProperty} value={backgroundProperty} as={Fragment}>
            {({ checked }) => (
              <div className=" flex w-full cursor-pointer items-center justify-between space-x-3 px-3">
                <div
                  className={`flex h-4 w-4 items-center justify-center rounded-full border ${
                    checked ? 'border-deep-orange' : 'border-slate-300'
                  }`}
                >
                  {checked && <div className="h-2.5 w-2.5 rounded-full bg-deep-orange"></div>}
                </div>
                <div className="flex grow items-center justify-between py-2">
                  <div className={classnames('text-sm', checked ? '' : 'text-slate-500')}>
                    {backgroundProperty === 'backgroundColor' ? 'Color' : 'Image'}
                  </div>
                  <div className="relative flex !h-[32px] !w-[32px] items-center">
                    {backgroundProperty === 'backgroundColor' ? (
                      <ColorPicker
                        classNames="rounded-2xl flex justify-center items-center"
                        pickerClassNames="right-8 top-0"
                        defaultColors={brandKitStore.colors}
                        defaultColorsLabel="Brand Colors"
                        onChange={onBgColorChange}
                        hex={selectedColor as string}
                      >
                        <IconColorPicker size={16} stroke={2} />
                        <BrandKitInlineEmptyState label="colors" />
                      </ColorPicker>
                    ) : (
                      <ImagePicker
                        defaultImagesLabel="Brand Backgrounds"
                        defaultImagesUrls={brandImagesUrls}
                        uploadedImagesUrls={Object.keys(uploadedImagesMap)}
                        onImagesUploaded={uploadFiles}
                        selectedImage={selectedImage}
                        onDelete={onDelete}
                        onImageSelected={onBgImageChange}
                      >
                        <BrandKitInlineEmptyState label="backgrounds" />
                      </ImagePicker>
                    )}
                  </div>
                </div>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </>
  );
}
