import { useIsFetching } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconPlaystationTriangle } from '@tabler/icons-react';
import { Transition } from '@headlessui/react';
import { useCallback, useMemo, useState, useSyncExternalStore } from 'react';
import ClipsListItemWithActions from './ClipsListItemWithActions';
import ClipListPreviewSkeleton from './ClipListPreviewSkeleton';
import CreateClipsPopover from './CreateClipsPopover';
import ClipDeletionErrorDialog, { ClipDeletionError } from './ClipDeletionErrorDialog';
import CreateAISearchClipsButton from './CreateAISearchClipsButton';
import { Clip } from '@/domains/asset';
import { deleteContentClip } from '@/libs/clipContentUtil';
import { classnames } from '@/libs/utils';
import useElementScrolledHook from '@/hooks/useElementScrolledHook';
import IconButton from '@/components/atoms/Button/IconButton';
import { clipGeneratingCount } from '@/stores/clip';
import AssetRatingDialog from '@/components/molecules/AssetRating/AssetRatingDialog';
import useSavedSearch from '@/hooks/useSavedSearch';
import { core } from '@/stores/core';

export default function ClipsList({
  clips,
  canGenerate,
  isCollapsed,
  isHidden,
  generateClips,
  onDelete,
  onRenameClip,
  onGenerateSocialPost,
  onDuplicateClip,
  toggleCollapse
}: {
  clips: Clip[];
  canGenerate: boolean;
  isCollapsed: boolean;
  isHidden: boolean;
  generateClips: () => Promise<void>;
  onDelete: (clipId: string) => void;
  onRenameClip: (clip: Clip) => void;
  onGenerateSocialPost: (clipId: string) => void;
  onDuplicateClip: (clipId: string) => void;
  toggleCollapse: () => void;
}) {
  const { broadcastId } = useParams<{ broadcastId: string }>();
  const isFetching = useIsFetching({ queryKey: ['clips', broadcastId] });
  const { isScrolled, handleOnScroll } = useElementScrolledHook();
  const [_, setSearchParams] = useSearchParams();
  const generatingCount = useSyncExternalStore(clipGeneratingCount.subscribe, clipGeneratingCount.getSnapshot);
  const [clipDeletionError, setClipDeletionError] = useState<ClipDeletionError>(null);
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const { isSavedSearchPage } = useSavedSearch();

  const deleteClip = useCallback(
    async (clipId: string) => {
      deleteContentClip(clipId)
        .then(() => {
          onDelete(clipId);
        })
        .catch((err: any) => {
          const parseError = JSON.parse(err);
          setClipDeletionError({ clipId, message: parseError?.message, textAssets: parseError?.text_assets });
        });
    },
    [onDelete]
  );

  const onCloseClipDeletionErrorDialog = useCallback(() => {
    setClipDeletionError(null);
  }, []);

  const isGeneratingClips = useMemo(() => {
    return !!generatingCount || (!!isFetching && !clips.length);
  }, [clips.length, generatingCount, isFetching]);

  const onClipSelect = useCallback(
    (clip: Clip) => {
      setSearchParams({ activeClipId: clip.id });
      if (coreStore.content?.id !== clip.content.id) {
        core.set(() => ({ content: clip.content }));
      }
    },
    [setSearchParams, coreStore.content]
  );

  return (
    <>
      {!isHidden && (
        <div className="absolute bottom-0 h-[69vh] w-full bg-gradient-to-t from-slate-950/[0.13] to-slate-950/0 blur"></div>
      )}
      <div
        className={classnames(
          'z-20 flex w-full shrink-0 flex-col items-stretch overflow-hidden rounded-2xl border !border-slate-100 bg-white shadow-xl shadow-slate-200/10 transition-all duration-[400ms]',
          { hidden: isHidden }
        )}
      >
        <div className="flex h-full flex-col">
          <div
            className={classnames(
              'sticky top-0 z-20 flex h-14 w-full items-center justify-between bg-white/90 p-4 px-5 backdrop-blur transition-all duration-150 ease-in-out',
              {
                'shadow-lg shadow-slate-900/[0.075]': isScrolled
              }
            )}
          >
            <div className="flex items-center space-x-2">
              <span className="text-lg font-medium">Clips</span>
              {!isFetching && (
                <span className="inline-flex rounded-full bg-slate-200 px-2.5 py-0.5 text-xs font-medium">
                  {clips.length}
                </span>
              )}
            </div>
            <div className="flex space-x-1.5">
              <IconButton
                icon={isCollapsed ? 'IconArrowBarRight' : 'IconArrowBarLeft'}
                title={isCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
                variation="text"
                trackingId="clips-list-collapse-button"
                onClick={toggleCollapse}
              />
            </div>
          </div>
          <div className="flex grow flex-col overflow-y-auto overflow-x-hidden" onScroll={handleOnScroll}>
            {!isFetching && !generatingCount && !clips.length ? (
              <div className="flex h-full w-full grow items-center justify-center">
                <div className="">
                  <div className="flex w-full justify-center">
                    <IconPlaystationTriangle
                      style={{ transform: 'rotate(90deg)' }}
                      className="h-7 w-7 text-slate-400"
                    />
                  </div>
                  <div className="mt-2.5 px-4 text-center text-sm text-slate-400">
                    <div className="font-medium">No clips, yet.</div>
                    {!isSavedSearchPage && (
                      <div className="mt-1 leading-tight">Highlight the transcript or generate automagically.</div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {!isSavedSearchPage && isGeneratingClips ? (
              <ClipListPreviewSkeleton count={generatingCount || 5} isCollapsed={isCollapsed} />
            ) : (
              <></>
            )}
            {!isFetching && clips.length ? (
              clips.map((clip: Clip) => (
                <Transition
                  key={clip.asset_metadata.id}
                  show={!clip.isRemoved}
                  leave="transition transform duration-500 delay-100 ease-out"
                  leaveFrom="transform translate-x-0"
                  leaveTo="transform translate-x-[300px]"
                >
                  <ClipsListItemWithActions
                    clip={clip}
                    isCollapsed={isCollapsed}
                    onDelete={deleteClip}
                    onRenameClip={onRenameClip}
                    onGenerateSocialPost={onGenerateSocialPost}
                    onClipSelect={onClipSelect}
                    onDuplicateClip={onDuplicateClip}
                  />
                </Transition>
              ))
            ) : (
              <></>
            )}
            {isSavedSearchPage && isGeneratingClips ? (
              <ClipListPreviewSkeleton count={generatingCount || 5} isCollapsed={isCollapsed} />
            ) : (
              <></>
            )}
          </div>
          <div className="border-t bg-white px-6 py-3">
            {isSavedSearchPage ? (
              <CreateAISearchClipsButton generateClips={generateClips} />
            ) : (
              <CreateClipsPopover generateClips={generateClips} canGenerate={canGenerate} />
            )}
          </div>
        </div>
      </div>
      <ClipDeletionErrorDialog
        isOpen={!!clipDeletionError}
        onClose={onCloseClipDeletionErrorDialog}
        error={clipDeletionError}
      />
      <AssetRatingDialog />
    </>
  );
}
