import { memo, useSyncExternalStore } from 'react';
import ClipUndoRedo from '@/components/molecules/ClipUndoRedo';
import { core } from '@/stores/core';
import DownloadsPopup from '@/components/molecules/DownloadsPopup/DownloadsPopup';
import ClipSharePopup from '@/components/molecules/SharePopup/ClipSharePopup/ClipSharePopup';

function EditRecordingHeader() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  return (
    <div className="flex items-center gap-2">
      <ClipUndoRedo isInline={true} />
      {!!coreStore.content?.id && <DownloadsPopup contentId={coreStore.content?.id} />}
      <ClipSharePopup />
    </div>
  );
}

export default memo(EditRecordingHeader);
