import { IconMoodPuzzled } from '@tabler/icons-react';

const PlayableClipOverlay = () => {
  return (
    <div
      id="clip-vision-analysis-failed-overlay"
      className="absolute inset-0 flex flex-col items-center justify-center rounded-md bg-gray-200 px-1"
    >
      <IconMoodPuzzled size={40} />
      <div className="mt-3 font-medium">Dang it! That took a while...</div>
      <div className="mt-1 text-center text-xs text-slate-700">
        You seem to have caught us at a weird spot. Please press Play to continue.
      </div>
    </div>
  );
};

export default PlayableClipOverlay;
