import { currentClip, saveClipChanges, trackClipUpdate, updateClipMetadataWithId } from '@/stores/clip';

export function resetFullClipChanges(videoAssetId: string, recordingDuration: number) {
  updateClipMetadataWithId(videoAssetId, {
    intro: undefined,
    outro: undefined,
    deletes: {},
    start: 0,
    end: recordingDuration
  });
  saveClipChanges(videoAssetId, false, true, currentClip.getSnapshot()[videoAssetId].asset_metadata.is_edited);
  trackClipUpdate(videoAssetId, 'RESET_FULL_RECORDING_CHANGES');
}
